export default {
    AWAITING_PROCESSING: 'AWAITING_PROCESSING',
    AWAITING_3DS_RESULT: 'AWAITING_3DS_RESULT',
    AWAITING_PURCHASE_AFTER_3DS_PROCESSING: 'AWAITING_PURCHASE_AFTER_3DS_PROCESSING',
    PURCHASE_ERROR: 'PURCHASE_ERROR',
    PAYOUT_ERROR: 'PAYOUT_ERROR',
    PURCHASE_DECLINE: 'PURCHASE_DECLINE',
    PAYOUT_DECLINE: 'PAYOUT_DECLINE',
    SUCCESS_PURCHASE: 'SUCCESS_PURCHASE',
    SUCCESS_TRANSFER: 'SUCCESS_TRANSFER',
    VALIDATION_ERROR: 'VALIDATION_ERROR',

    mainpageUrl: 'https://rocketpay.kz',
    offerUrl: 'https://c2c.rocketpay.kz/p2p_rp_oferta.pdf',
    currency: 'KZT',
    minAmount: 10000,
    maxAmount: 100000000,
    feePercent: '1,6',
    prefix:'/p2ptransfer/',
    requestDelay: 3000
};
