<template>
    <div
        class="container"
    >
        <div class="row ">
            <div class="col">
                <a
                    :href=mainpageUrl
                    alt=""
                >
                    <div
                        class="logo"
                    />
                </a>
            </div>
        </div>
        <div class="row ">
            <div class="col py-4">
                <h1>Переводы с карты на карту</h1>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-lg-5">
                <div class="card">
                    <div class="card-type">
                        <!-- <div class="mir" /> -->
                        <div class="ms" />
                        <div class="mc" />
                        <div class="visa" />
                    </div>
                    <div class="control-group">
                        <label
                            v-if="
                                ($v.cardsData.from.pan.$dirty && !$v.cardsData.from.pan.required) ||
                                    ($v.cardsData.from.pan.$dirty && !$v.cardsData.from.pan.panCheck)
                            "
                            class="form-label text-danger"
                        >
                            Введите правильный номер карты
                        </label>

                        <label
                            v-else
                            class="form-label"
                        >
                            Номер карты
                        </label>
                        <input
                            class="form-control"
                            v-model="fromPan"
                            v-mask="'#### #### #### #######'"
                            type="text"
                            @input="changeFromPan()"
                        >
                    </div>
                    <div class="control-group">
                        <label
                            v-if="($v.cardsData.from.card_holder.$dirty && !$v.cardsData.from.card_holder.required) ||
                                ($v.cardsData.from.card_holder.$dirty && !$v.cardsData.from.card_holder.minLength) ||
                                ($v.cardsData.from.card_holder.$dirty && !$v.cardsData.from.card_holder.checkLatin)

                            "
                            class="form-label text-danger"
                        >
                            Введите правильное имя владельца
                        </label>
                        <label
                            v-else
                            class="form-label"
                        >
                            Имя владельца
                        </label>
                        <input
                            class="form-control"
                            v-model="cardsData.from.card_holder"
                            v-model.trim="$v.cardsData.from.card_holder.$model"
                            type="text"
                        >
                    </div>
                    <div class="row ">
                        <div class="col">
                            <div class="control-group exp">
                                <label
                                    v-if="($v.cardsData.from.month.$dirty && !$v.cardsData.from.month.required) ||
                                        ($v.cardsData.from.month.$dirty && !$v.cardsData.from.month.between) ||
                                        ($v.cardsData.from.year.$dirty && !$v.cardsData.from.year.required) ||
                                        ($v.cardsData.from.year.$dirty && !$v.cardsData.from.year.between)
                                    "
                                    class="form-label text-danger"
                                >
                                    Введите правильну дату
                                </label>

                                <label
                                    v-else
                                    class="form-label"
                                >
                                    Срок действия
                                </label>
                                <div class="row">
                                    <div class="col">
                                        <select class="form-select" v-model="cardsData.from.month">
                                            <option value='' selected disabled></option>
                                            <option v-bind:key="month" v-bind:value="month" v-for="month in ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']">{{month}}</option>
                                        </select>
                                        <!-- <input
                                            v-model="cardsData.from.month"
                                            v-model.trim="$v.cardsData.from.month.$model"
                                            v-mask="'##'"
                                            type="text"
                                            autocomplete="off"
                                        > -->
                                    </div>
                                    <div class="col">
                                        <select class="form-select" v-model="fromYear" @change="changeYear">
                                            <option value='' selected disabled></option>
                                            <option v-bind:key="year" v-bind:value="year" v-for="year in this.tenYears()">{{year}}</option>
                                        </select>
                                        <!-- <input
                                            v-model="fromYear"
                                            v-mask="'##'"
                                            type="text"
                                            autocomplete="off"
                                            @input="changeYear"
                                        > -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="control-group">
                                <label
                                    v-if="($v.cardsData.from.cvv.$dirty && !$v.cardsData.from.cvv.required) ||
                                        ($v.cardsData.from.cvv.$dirty && !$v.cardsData.from.cvv.minLength)

                                    "
                                    class="form-label text-danger"
                                >
                                    Введите правильный  CVV
                                </label>
                                <label
                                    v-else
                                    class="form-label"
                                >
                                    CVV
                                </label>
                                <input
                                    class="form-control"
                                    v-model="cardsData.from.cvv"
                                    v-model.trim="$v.cardsData.from.cvv.$model"
                                    v-mask="'###'"
                                    type="password"
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        class="row"
                        style="padding-top: 20px"
                    >
                        <div class="col">
                            <div class="control-group">
                                <label
                                    v-if="$v.cardsData.from.first_name.$dirty && !$v.cardsData.from.first_name.required"
                                    class="form-label text-danger"
                                >
                                    Введите имя
                                </label>
                                <label
                                    v-else
                                    class="form-label"
                                >
                                    Имя
                                </label>
                                <input
                                    class="form-control"
                                    v-model="cardsData.from.first_name"
                                    type="text"
                                    name="first_name"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="control-group">
                                <label
                                    v-if="$v.cardsData.from.last_name.$dirty && !$v.cardsData.from.last_name.required"
                                    class="form-label text-danger"
                                >
                                    Введите фамилию
                                </label>

                                <label
                                    v-else
                                    class="form-label"
                                >
                                    Фамилия
                                </label>
                                <input
                                    class="form-control"
                                    v-model="cardsData.from.last_name"
                                    type="text"
                                    name="last_name"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="control-group">
                                <label
                                    v-if="$v.cardsData.from.address.$dirty && !$v.cardsData.from.address.required"
                                    class="form-label text-danger"
                                >
                                    Введите адрес
                                </label>
                                <label
                                    v-else
                                    class="form-label"
                                >
                                    Адрес
                                </label>
                                <input
                                    class="form-control"
                                    v-model="cardsData.from.address"
                                    name="address"
                                    type="text"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="control-group">
                                <label
                                    v-if="($v.cardsData.from.phone.$dirty && !$v.cardsData.from.phone.required)
                                        || ($v.cardsData.from.phone.$dirty && !$v.cardsData.from.phone.minLength)"
                                    class="form-label text-danger"
                                >
                                    Введите телефон
                                </label>
                                <label
                                    v-else
                                    class="form-label"
                                >
                                    Телефон
                                </label>
                                <input
                                    class="form-control"
                                    v-model="cardsData.from.phone"
                                    v-mask="'+#########################'"
                                    name="phone"
                                    type="text"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-flex col-05">
                <div class="arrow-wrapper-horizontal">
                    <svg class="arrow" viewBox="0 0 36 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 75V0L36 38L0 75Z"/>
                    </svg>
                </div>
            </div>
            <div class="col-12 d-lg-none">
                <div class="d-block d-lg-none arrow-wrapper-vertical">
                    <svg class="arrow" viewBox="0 0 76 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 0.499997L75.5 0.5L37.5 36.5L0.5 0.499997Z"/>
                    </svg>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="card">
                    <div class="card-type">
                        <!-- <div class="mir" /> -->
                        <div class="ms" />
                        <div class="mc" />
                        <div class="visa" />
                    </div>
                    <div class="control-group">
                        <label
                            v-if="
                                ($v.cardsData.to.pan.$dirty && !$v.cardsData.to.pan.required) ||
                                    ($v.cardsData.to.pan.$dirty && !$v.cardsData.to.pan.panCheck)
                            "
                            class="form-label text-danger"
                        >
                            Введите правильный номер карты
                        </label>

                        <label
                            v-else
                            class="form-label"
                        >
                            Номер карты
                        </label>
                        <input
                            class="form-control"
                            v-model="toPan"
                            v-mask="'#### #### #### #######'"
                            type="text"
                            @input="changeToPan()"
                        >
                    </div>
                </div>
                <div class="last-block">
                    <div
                        class="row"
                        style="padding-top: 20px"
                    >
                        <div class="col col-lg-7 px-lg-2">
                            <div class="control-group">
                                <label
                                    v-if="$v.cardsData.amount.$dirty && !$v.cardsData.amount.required"
                                    class="form-label text-danger"
                                >
                                    Введите верную сумму
                                </label>
                                <label
                                    v-else-if="$v.cardsData.amount.$dirty && !$v.cardsData.amount.minValue"
                                    class="form-label text-danger"
                                >
                                    Минимальный перевод: {{getMinAmount()}} тенге.
                                </label>
                                <label
                                    v-else-if="$v.cardsData.amount.$dirty && !$v.cardsData.amount.maxValue"
                                    class="form-label text-danger"
                                >
                                    Максимальный перевод: {{getMaxAmount()}} тенге.
                                </label>

                                <label
                                    v-else
                                    class="form-label"
                                >
                                    Сумма перевода
                                </label>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="form-control"
                                        v-model="majorAmount"
                                        v-mask="'########################'"
                                        type="text"
                                        v-debounce:200ms="minorAmount"
                                    >
                                    <div class="ms-2 form-text">
                                        тенге.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <p class="terms-text">С&nbsp;карты на&nbsp;карту любых казахстанских Банков&nbsp;1,6% минимум — {{ getMinAmount() }}&nbsp;тенге*.</p>
                            <p class="terms-text">Размер эмитенсткой комиссии зависит от&nbsp;банка эмитента карты отправителя.</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <p class="terms-text">Нажимая кнопку "Перевести", Вы принимаете условия
                                <a :href=offerUrl target="_blank"> договора оферты</a>.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col lg-8 offset-lg-4">
                            <button
                                class="btn btn-primary"
                                type="button"
                                :disabled="getDisabledButton"
                                @click="submitForm()"
                            >
                                Перевести
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p class="form-text text-danger">
                                {{ getValidationError }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-md-center py-4">
            <div class="col col-lg-8 px-lg-12">
                <p>Банки-эмитенты карт могут устанавливать дополнительные условия и&nbsp;ограничения для проведения переводов с&nbsp;карты на&nbsp;карту.</p>

                <p>Зачисление на&nbsp;карты банков Казахстана, Украины (Visa), Беларуси происходит моментально. На&nbsp;карты банков других стран срок зависит от&nbsp;банка получателя и&nbsp;может составлять от&nbsp;нескольких минут до&nbsp;нескольких дней.</p>
            </div>
        </div>
        <div class="row justify-content-md-center py-4">
            <div class="pci_logo"></div>
        </div>
        <form
            id="acs_form"
            method="POST"
            action=""
            target="acs-iframe"
        >
            <input
                type="hidden"
                value=""
                name="PaReq"
            >
            <input
                type="hidden"
                value=""
                name="MD"
            >
            <input
                type="hidden"
                value=""
                name="TermUrl"
            >
        </form>
        <div
            id="acs-iframe-wrapper"
            :class="{'show': getShowFrame}"
        >
            <iframe id="acs-iframe" name="acs-iframe" />
        </div>
    </div>
</template>

<script>
import { required, minLength, minValue, maxValue, between } from 'vuelidate/lib/validators';
import appConfig from "@/appConfig";
import Utils from '@/helpers/Utils';
import {mapActions, mapGetters} from 'vuex';

const yearNow = new Date().getUTCFullYear();
const url = new URL(document.location.href);
const searchParams = new URLSearchParams(url.search);
let minAmount = searchParams.get('test') === 'true' ? 100 : appConfig.minAmount;
export default {
    name: "MainPage",
    validations:{
        cardsData: {
            from: {
                pan: {
                    required,
                    panCheck(pan) {
                        return Utils.checkCardNumber(pan);
                    }
                },
                card_holder : {
                    required,
                    minLength: minLength(5),
                    checkLatin(card_holder) {
                        const regex = /^[a-zA-Z-._ ]*$/g;
                        return regex.test(card_holder);
                    }
                },
                month:{
                    required,
                    between: between(1,12)
                },
                year:{
                    required,
                    between: between(yearNow, yearNow + 10)
                },
                cvv: {
                    required,
                    minLength: minLength(3)
                },


                first_name: {
                    required: Utils.needValidate() ? required : false,
                },
                last_name: {
                    required: Utils.needValidate() ? required : false,
                },
                address: {
                    required: Utils.needValidate() ? required : false,
                },
                phone: {
                    required: Utils.needValidate() ? required : false,
                    minLength: minLength(5)
                }
            },
            to: {
                pan: {
                    required,
                    panCheck(pan) {
                        return Utils.checkCardNumber(pan);
                    }
                }
            },
            amount: {
                required,
                minValue: minValue(minAmount),
                maxValue: maxValue(appConfig.maxAmount),
            },
            fee: ''
        },

    },
    data: () => ({
        cardsData: {
            from: {
                pan: '',
                year: '',
                month: '',
                card_holder: '',
                cvv: '',
                first_name: '',
                last_name:'',
                address: '',
                phone: ''
            },
            to: {
                pan: ''
            },
            amount: '',
            currency: appConfig.currency,
            payment_id: '',
            fee:''
        },
        majorAmount: '',
        fromPan:'',
        toPan:'',
        fromYear:'',
        mainpageUrl: appConfig.mainpageUrl,
        offerUrl: appConfig.offerUrl,
        domain: '',


    }),
    computed:{
        ...mapGetters([
            'getShowFrame',
            'getDisabledButton',
            'getPaymentId',
            'getValidationError',
            'getFee'
        ]),
    },
    methods: {
        ...mapActions([
            'setStateLoader',
            'initSaleOrGetFee',
            'setPaymentId',
            'setDisabledButton'
        ]),
        async minorAmount() {
            this.cardsData.amount = this.majorAmount + '00';
            if(this.$v.cardsData.amount.$dirty && this.$v.cardsData.amount.minValue && this.$v.cardsData.amount.maxValue) {
                console.log("Amount:", this.cardsData.amount / 100);
                await this.initSaleOrGetFee([`{"amount" : "${this.cardsData.amount}" }`, 'getFee']);
                this.cardsData.fee = this.getFee.toString();
                console.log('Fee: ', this.cardsData.fee / 100)
            }
            this.$v.cardsData.amount.$touch();
        },
        changeFromPan() {
            this.cardsData.from.pan = this.fromPan.replace(/\s/g, '');
            this.$v.cardsData.from.pan.$touch();
            // console.log("fromPanValidator:", this.fromPan, this.cardsData.from.pan);
        },
        changeToPan() {
            this.cardsData.to.pan = this.toPan.replace(/\s/g, '');
            this.$v.cardsData.to.pan.$touch();
            // console.log("toPanValidator:", this.toPan, this.cardsData.to.pan);
        },
        changeYear() {
            // console.log("Year selected: ", this.cardsData.from.year)
            this.cardsData.from.year = '';
            this.cardsData.from.year = parseInt(this.fromYear, 10) + 2000;
            this.cardsData.from.year = this.cardsData.from.year.toString();
            this.$v.cardsData.from.year.$touch();
        },
        getMinAmount() {
            return Math.round(minAmount / 100);
        },
        getMaxAmount() {
            return Math.round(appConfig.maxAmount / 100);
        },
        getFee() {
            return appConfig.feePercent;
        },
        tenYears() {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((x) => parseInt((new Date().getFullYear() + x).toString().substring(2)));
        },
        submitForm() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                // console.log('form validation error');
            } else {
                // console.log('form validation passed', this.$v.$model);
                this.setDisabledButton(true);
                this.setStateLoader(true);
                this.cardsData.payment_id = new Date().getTime();
                if(this.getPaymentId === null) {
                    this.setPaymentId(this.cardsData.payment_id);
                }
                this.initSaleOrGetFee([JSON.stringify(this.cardsData)]);
            }
        }
    },
        mounted() {
            this.domain = window.location.hostname.split('.').slice(-2).join('.');
    }
}
</script>

<style scoped lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

$card-height: 320px;

$color-primary: #393939;
$color-accent: #b24901;

$color-hover: #2c6d74;
$color-inverted: #fff;
$color-error: #f01111;

$bg-primary: #fff;
$bg-accent: $color-accent;
$bg-accent-inverted: $color-hover;

$frame-color: $color-accent;
$arrow-color: #b2490188;
$button-bg: #b24901;
$button-hover-bg: #1b6d73;
$button-color: $color-inverted;

$bg-control: $bg-primary;

$logo-url: url('../assets/logo.png');
$logo-height: 4rem;

h1 {
    // font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 24px;
    color: $color-primary;
}
p {
    font-family: 'Roboto', sans-serif;
    color: $color-primary;
    padding-bottom: 15px;
    &.errorText {
        color: $color-error;
    }
}

.terms-text {
    font-size: .75em;
    padding: 0;
    margin-bottom: .5em;
}

a {
    color: $color-accent;
    transition: all .25s;
    &:hover {
        color: $color-hover;
    }
    &:visited {
        color: $color-accent;
    }
}
.logo {
    height: $logo-height;
    background-repeat: no-repeat;
    background-position: 0;
    background-image: $logo-url;
    background-size: contain;
    height: 2rem;
    margin-top: 2rem;
}
// .logo {
//     height: $logo-height;
//     background-repeat: no-repeat;
//     background-position: 0 center;
//     background-image: $logo-url;
//     background-size: contain;
//     margin-top: 2rem;
// }

.card {
    min-height: $card-height;
    padding: 20px;
    opacity: 0.9;
    border-radius: 22px;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    border: solid 3px $frame-color;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #f4f4f4;
}

.control-group {
    margin-bottom: 8px;
}

.arrow-wrapper-horizontal {
    height: $card-height;
    max-width: 30px;
}
.arrow-wrapper-vertical {
    height: 30px;
    margin: 30px;
}
.arrow {
    width: 100%;
    height: 100%;
    fill: $color-accent;
    fill-opacity: .5;
}

button {
    // font-family: 'Roboto', sans-serif;
    // border: none;
    width: 100%;
    // height: 40px;
    // border-radius: 3px;
    background-color: $button-bg;
    border-color: $button-bg;

    // font-size: 18px;
    // font-weight: bold;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1.78;
    // letter-spacing: 0.36px;
    color: $button-color;
    // cursor: pointer;
    // outline: none;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    transition: all .15s;
    &:hover {
        background-color: $button-hover-bg;
        border-color: $button-hover-bg;

    }
    // &:focus {
    //     box-shadow: none;
    // }
}

.card-type {
    overflow: hidden;

    & div {
        height: 27px;
        width: 48px;
        float: right;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    & .visa {
        background-image: url('../assets/visa.png');
    }

    & .mc {
        background-image: url('../assets/mc.png');
    }

    & .ms {
        background-image: url('../assets/ms.png');
    }

    & .mir {
        background-image: url('../assets/mir.png');
    }
}

#acs-iframe-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;
    background: #f4f4f4;
    z-index: 200;
    &.show {
        display: block;
    }
    & iframe {
        min-width: 100%;
        height: 100%;
    }
}

.pci_logo {
    display: inline-block;
    height: 40px;
    width: 100px;
    background: url('../assets/pci.svg') 0 0 no-repeat;
    background-size: contain;
}

.last-block {
    height: 100%;
}
.align-right {
    display: flex;
    justify-content: flex-end;
    background-color: #DA7349;
}
.col-05 {
    flex: 0 0 auto;
    width: 4%;
}
</style>
